<template>
  <v-container fluid class="down-top-padding">
    <BaseBreadcrumb :title="page.title" :module_name="page.name"/>
    <v-row>
      <v-col>
        <asset-panel ref="platforms" :editable="null" :module_name="page.name"/>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import AssetPanel from "../../components/commonComponents/AssetPanelV2";
import BaseBreadcrumb from "../../components/commonComponents/BaseBreadcrumb";
export default {
  components: {
    BaseBreadcrumb,
    AssetPanel,
  },
  data() {
    return {
      page: {
        title: this.$store.getters.translate("platforms"),
        name: "platforms",
        model: "asset",
      },
    };
  },
  mounted() {
    if(this.$route.params.asset_id) {
      this.$refs.platforms.setPlatformId(this.$route.params.asset_id, 'child');
    }
    else {
      this.$refs.platforms.setPlatformId(0, 'all-platforms');
    }
  },
};
</script>